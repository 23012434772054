import { TSelectOption } from 'moralis-ui';
import { api } from '@/utils/api';
import { logApp } from '@/utils/logApp';

const log = logApp.create('utils/tokenExplorer/FilterCategories');

// TODO refactor to use API without async breaking the TokenMapping class
export const getFilterCategories = async () => {
  try {
    const result = await api.category.getCategories(false, true);
    return result.map((cat) => {
      return { label: cat.name, ...cat };
    });
  } catch (error) {
    log.error('[getFilterCategories]', error);
    return [];
  }
};

export type CoinCategory = {
  id: number;
  value: string;
  name: string;
  type: 'main' | 'sub';
  parentCategoryId: number | null;
};

export const categoriesResultHardcoded: CoinCategory[] = [
  {
    id: 1,
    value: 'layer-1',
    name: 'Layer 1 (L1)',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 2,
    value: 'stablecoins',
    name: 'Stablecoins',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 4,
    value: 'decentralized-finance-defi',
    name: 'Decentralized Finance (DeFi)',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 5,
    value: 'meme-token',
    name: 'Meme',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 6,
    value: 'governance',
    name: 'Governance',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 7,
    value: 'non-fungible-tokens-nft',
    name: 'NFT',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 8,
    value: 'layer-2',
    name: 'Layer 2 (L2)',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 9,
    value: 'gaming',
    name: 'Gaming (GameFi)',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 10,
    value: 'metaverse',
    name: 'Metaverse',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 11,
    value: 'depin',
    name: 'DePIN',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 12,
    value: 'privacy-coins',
    name: 'Privacy Coins',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 13,
    value: 'storage',
    name: 'Storage',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 14,
    value: 'oracle',
    name: 'Oracle',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 15,
    value: 'wrapped-tokens',
    name: 'Wrapped-Tokens',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 16,
    value: 'internet-of-things-iot',
    name: 'Internet of Things (IOT)',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 17,
    value: 'artificial-intelligence',
    name: 'Artificial Intelligence (AI)',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 18,
    value: 'fan-tokens',
    name: 'Fan Tokens',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 19,
    value: 'analytics',
    name: 'Analytics Tokens',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 20,
    value: 'identity',
    name: 'Decentralized Identity',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 21,
    value: 'interoperability',
    name: 'Interoperability',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 22,
    value: 'telegram-bots',
    name: 'Telegram Bots',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 23,
    value: 'platform-based-utility-tokens',
    name: 'Platform-Based Utility Tokens',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 24,
    value: 'exchange-issued-asset-tokens',
    name: 'Exchange-Issued Asset Tokens',
    type: 'main',
    parentCategoryId: null,
  },
  {
    id: 25,
    value: 'liquid-staking-tokens',
    name: 'Liquid Staking Tokens',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 26,
    value: 'decentralized-exchange',
    name: 'Decentralized Exchange (DEX)',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 27,
    value: 'yield-farming',
    name: 'Yield Farming',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 28,
    value: 'zero-knowledge-zk',
    name: 'Zero Knowledge (ZK)',
    type: 'sub',
    parentCategoryId: 12,
  },
  {
    id: 29,
    value: 'play-to-earn',
    name: 'Play To Earn',
    type: 'sub',
    parentCategoryId: 9,
  },
  {
    id: 30,
    value: 'lending-borrowing',
    name: 'Lending/Borrowing',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 31,
    value: 'liquid-staking-governance-tokens',
    name: 'Liquid Staking Governance Tokens',
    type: 'sub',
    parentCategoryId: 6,
  },
  {
    id: 32,
    value: 'asset-backed-tokens',
    name: 'Asset-backed Tokens',
    type: 'sub',
    parentCategoryId: 15,
  },
  {
    id: 33,
    value: 'decentralized-derivatives',
    name: 'Decentralized Derivatives',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 34,
    value: 'real-world-assets-rwa',
    name: 'Real World Assets (RWA)',
    type: 'sub',
    parentCategoryId: 15,
  },
  {
    id: 35,
    value: 'decentralized-perpetuals',
    name: 'Decentralized Perpetuals',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 36,
    value: 'masternodes',
    name: 'Masternodes',
    type: 'sub',
    parentCategoryId: 1,
  },
  {
    id: 37,
    value: 'tokenized-gold',
    name: 'Tokenized Gold',
    type: 'sub',
    parentCategoryId: 15,
  },
  {
    id: 38,
    value: 'insurance',
    name: 'Decentralized Insurance ',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 39,
    value: 'nft-marketplace',
    name: 'NFT Marketplace',
    type: 'sub',
    parentCategoryId: 7,
  },
  {
    id: 40,
    value: 'prediction-markets',
    name: 'Prediction Markets',
    type: 'sub',
    parentCategoryId: 17,
  },
  {
    id: 41,
    value: 'decentralized-options',
    name: 'Decentralized Options',
    type: 'sub',
    parentCategoryId: 4,
  },
  {
    id: 42,
    value: 'cross-chain-communication',
    name: 'Cross Chain Communication',
    type: 'sub',
    parentCategoryId: 21,
  },
  {
    id: 43,
    value: 'metagovernance',
    name: 'Metagovernance',
    type: 'sub',
    parentCategoryId: 6,
  },
  {
    id: 44,
    value: 'rebase-tokens',
    name: 'Rebase Tokens',
    type: 'sub',
    parentCategoryId: 2,
  },
  {
    id: 45,
    value: 'move-to-earn',
    name: 'Move To Earn',
    type: 'sub',
    parentCategoryId: 9,
  },
  {
    id: 46,
    value: 'automated-market-maker-amm',
    name: 'Automated Market Maker (AMM)',
    type: 'sub',
    parentCategoryId: 26,
  },
  {
    id: 47,
    value: 'yield-aggregator',
    name: 'Yield Aggregator',
    type: 'sub',
    parentCategoryId: 27,
  },
  {
    id: 48,
    value: 'lsdfi',
    name: 'LSDFi',
    type: 'sub',
    parentCategoryId: 25,
  },
];

export const formatCoinCategoriesToSelectOptions = (): TSelectOption[] =>
  categoriesResultHardcoded.map((category) => ({
    id: String(category.id),
    label: category.name,
  }));
