import { CoinFilterDto, SortingDto } from '@/services/index';
import { EFilterOperator } from '@/types/filter.types';
import { ERoutePath } from '@/utils/ERoutePath';
import { CHAIN_ID_MAP } from '@/utils/supportedChains';

const REQUEST_LIMIT_ENTITIES = 200;

export const getTokenExplorerLinkFilters = (chainId: string | null, categoryID: number | null): string => {
  if (!categoryID) {
    return ERoutePath.tokenExplorer;
  }

  const usedChainId = chainId || CHAIN_ID_MAP.ethereum;

  const filters = JSON.stringify({
    filters: [
      {
        filterKey: CoinFilterDto.filterName.COIN_CATEGORIES,
        filterOperator: EFilterOperator.EQ,
        filterUnit: categoryID,
      },
    ],
  });

  const sorting = JSON.stringify({
    filterName: CoinFilterDto.filterName.BLOCK_TIMESTAMP,
    orderBy: SortingDto.orderBy.DESC,
    limit: REQUEST_LIMIT_ENTITIES,
  });

  return `${ERoutePath.tokenExplorer}?chainId=${usedChainId}&filters=${encodeURIComponent(
    filters,
  )}&sorting=${encodeURIComponent(sorting)}`;
};
