export type TFormFilter = {
  filterKey?: string;
  filterOperator?: string | boolean;
  filterTimeFrame?: string;
  filterValue?: string;
  index?: number;
};
export interface IFilterFormValue {
  filters: TFormFilter[];
}

export enum EFilterOperator {
  EQ = 'eq',
  GT = 'gt',
  LT = 'lt',
  BWT = 'between',
}

export type TRangeOperator = EFilterOperator.GT | EFilterOperator.LT;

export type RangeSelector = TRangeOperator | EFilterOperator.BWT;

export type TFormOperator = EFilterOperator.EQ | TRangeOperator;
